/*
---------------------------------------
ttl
---------------------------------------
*/

.p_ttl {
	&--heading {
		@include min();
		color: $green;
		letter-spacing: 0.45em;
		text-align: center;
		margin-bottom: 30px;

		&--sub {
			@include din2014();
			font-size: 17px;
			letter-spacing: 0.1em;
			display: block;
		}

		@include sp() {
			font-size: 25px;
		}

		@include pc() {
			font-size: 30px;
		}
	}

	&--heading-contents {
		@include serif();
		color: $green;
		font-weight: 600;
		letter-spacing: 0.37em;

		@include sp() {
			font-size: 16px;
			line-height: 1.56;
		}

		@include pc() {
			font-size: 22px;
			line-height: 1.81;
			text-align: center;
			margin-top: 45px;
		}
	}
}
