/*
---------------------------------------
footer
---------------------------------------
*/

.l_footer {
	color: $green;
	text-align: center;

	&--wrapper {
		border-top: 1px solid $green;
	}

	&--cr {
		font-size: 10px;
		letter-spacing: 0.03em;
	}

	// SP
	@include sp() {
		padding: 60px 0 10px;

		&--wrapper {
			padding-top: 15px;
		}

		&--name {
			font-size: 12px;
			line-height: 1.2;
		}
	}

	// PC
	@include pc() {
		padding: 120px 0 70px;

		&--wrapper {
			padding-top: 47px;
		}

		&--name {
			font-size: 16px;
			letter-spacing: 0.3em;
		}

		&--navi {
			margin: 5px 0 10px;

			ul {
				justify-content: center;
			}

			li {
				margin-right: 50px;
			}

			a {
				font-weight: 500;
			}
		}
	}
}
