/*
---------------------------------------
Animation
---------------------------------------
*/

// Hamburger Menu Animation

@include keyframes(naviLineFirstOpen) {
	//上下バーから中央バーまでの距離をtranslateYで指定
	49% {
		opacity: 1;
		-webkit-transform: translate(0, 6px);
		transform: translate(0, 6px);
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 0;
		-webkit-transform: translate(0, 6px);
		transform: translate(0, 6px);
	}
}

@include keyframes(naviLineFirstClose) {
	0% {
		opacity: 0;
		-webkit-transform: translate(0, 6px);
		transform: translate(0, 6px);
	}

	50% {
		opacity: 0;
		-webkit-transform: translate(0, 6px);
		transform: translate(0, 6px);
	}

	51% {
		opacity: 1;
	}

	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}

@include keyframes(naviLineLastOpen) {
	49% {
		opacity: 1;
		-webkit-transform: translate(0, -6px);
		transform: translate(0, -6px);
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 0;
		-webkit-transform: translate(0, -6px);
		transform: translate(0, -6px);
	}
}

@include keyframes(naviLineLastClose) {
	0% {
		opacity: 0;
		-webkit-transform: translate(0, -$translateY);
		transform: translate(0, -$translateY);
	}

	50% {
		opacity: 0;
		-webkit-transform: translate(0, -$translateY);
		transform: translate(0, -$translateY);
	}

	51% {
		opacity: 1;
	}

	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}

@include keyframes(naviLineSecondOpen) {
	50% {
		-webkit-transform: rotateZ(0);
		transform: rotateZ(0);
	}

	100% {
		-webkit-transform: rotateZ(45deg);
		transform: rotateZ(45deg);
	}
}

@include keyframes(naviLineSecondClose) {
	0% {
		-webkit-transform: rotateZ(45deg);
		transform: rotateZ(45deg);
	}

	50% {
		-webkit-transform: rotateZ(0);
		transform: rotateZ(0);
	}
}

@include keyframes(naviLineSecondAfterOpen) {
	49% {
		opacity: 0;
	}

	50% {
		opacity: 1;
		-webkit-transform: rotateZ(0);
		transform: rotateZ(0);
	}

	100% {
		opacity: 1;
		-webkit-transform: rotateZ(-90deg);
		transform: rotateZ(-90deg);
	}
}

@include keyframes(naviLineSecondAfterClose) {
	0% {
		opacity: 1;
		-webkit-transform: rotateZ(-90deg);
		transform: rotateZ(-90deg);
	}

	50% {
		opacity: 1;
		-webkit-transform: rotateZ(0);
		transform: rotateZ(0);
	}

	51% {
		opacity: 0;
	}
}
