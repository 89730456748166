/*
---------------------------------------
sand-sale
---------------------------------------
*/

.pl_sand-sale {
	&--cover {
		@include sp() {
			background-image: url(../images/sand-sale/sp/cover.jpg);
		}

		@include pc() {
			background-image: url(../images/sand-sale/cover.jpg);
		}
	}

	// CUURENT
	&--current {
		@include sp() {
			margin-top: 110px;
		}

		@include pc() {
			margin-top: 130px;
		}

		&-heading {
			@include serif();
			color: $green;
			letter-spacing: 0.37em;

			@include sp() {
				font-size: 18px;
			}

			@include pc() {
				font-size: 22px;
				padding: 0 80px;
			}
		}

		// map
		&-map {
			&-display {
				background: #ccc;
				@include sp() {
					width: 100%;
					padding-bottom: 128%;
					margin-top: 30px;
				}

				@include pc() {
					@include inner($width: 1200px);
					padding-bottom: 480px;
					margin-top: 15px;
					border-radius: 20px;
				}
			}

			&-list {
				@include sp() {
					margin-top: 20px;
				}

				@include pc() {
					@include flex($ai: flex-end) {
						justify-content: space-between;
					}
					padding: 0 80px;
					margin-top: 30px;
				}

				li {
					border-bottom: 1px solid #ccc;

					@include sp() {
						letter-spacing: 0.1em;
						margin-top: 20px;

						&:first-child {
							margin-top: 0;
						}
					}

					@include pc() {
						width: 320px;
						padding: 0 6px;
					}

					a {
						display: flex;
						align-items: center;
						padding-bottom: 5px;
					}

					img {
						width: 22px;
						margin-right: 14px;
					}

					h2 {
						@include sp() {
							font-size: 14px;
						}

						@include pc() {
							font-size: 12px;
							letter-spacing: 0.1em;
						}
					}
				}
			}
		}

		// info
		&-info {
			@include pc() {
				margin-top: 100px;
				padding-bottom: 150px;
			}

			@include pc() {
				&-inner {
					@include flex($ai: flex-start) {
						justify-content: center;
					}

					&::before {
						content: "";
						display: block;
						width: 429px;
						height: 973px;
						background: url(../images/sand-sale/strata.jpg) no-repeat center;
						background-size: contain;
						margin-right: 115px;
					}
				}

				&-list {
					margin-top: 20px;
				}
			}

			&-item {
				@include sp() {
					margin-top: 40px;
				}

				@include pc() {
					width: 554px;
					margin-top: 50px;
				}

				&-heading {
					h2 {
						background: #e6e6e6;
						border-radius: 5px;

						@include sp() {
							font-size: 16px;
							padding: 7px 25px;
						}

						@include pc() {
							font-size: 18px;
							padding: 9px 25px;
							position: relative;

							&::before,
							&::after {
								content: "";
								display: block;
								position: absolute;
								top: 0;
								left: 0;
								background: $d_green;
							}

							&::before {
								width: 8px;
								height: 8px;
								border-radius: 4px;
								transform: translate(-145px, 21px);
							}

							&::after {
								width: 145px;
								height: 1px;
								border-radius: 4px;
								transform: translate(-145px, 24px);
							}
						}
					}
				}

				@include pc() {
					&-body {
						@include flex($ai: flex-end) {
							justify-content: space-between;
						}
						padding: 0 25px;
					}
				}

				&-detail {
					@include pc() {
						width: 285px;
					}

					dt {
						@include sp() {
							margin-top: 15px;
						}

						@include pc() {
							margin-top: 10px;
						}

						h3 {
							@include sp() {
								color: #fff;
								background: #666;
								font-size: 12px;
								display: inline-block;
								padding: 6px 17px;
								border-radius: 5px;
							}

							@include pc() {
								font-size: 14px;
							}
						}
					}

					dd {
						font-size: 12px;

						@include sp() {
							margin-top: 7px;
						}

						@include pc() {
							line-height: 1.75;
						}
					}
				}

				&-inventory {
					@include sp() {
						@include flex($ai: center) {
							justify-content: space-between;
						}
						margin-top: 15px;

						&-image {
							width: 41%;
							transform: translate(-10%, 0px);

							img {
								width: 100%;
							}
						}
					}

					@include pc() {
						width: 215px;
					}

					&-contents {
						@include sp() {
							text-align: right;
							width: 59%;
						}

						@include pc() {
							dl {
								@include flex($ai: flex-end) {
									justify-content: space-evenly;
								}
							}
						}

						h3 {
							color: $green;
							letter-spacing: 0.3em;

							@include sp() {
								font-size: 14px;
								transform: translate(-25px, 3px);
							}

							@include pc() {
								font-size: 12px;
							}
						}

						dd {
							line-height: 1;

							@include sp() {
								font-size: 21px;
							}

							span {
								letter-spacing: 0.06em;

								@include sp() {
									font-size: 45px;
								}

								@include pc() {
									font-size: 30px;
								}
							}
						}

						a {
							margin-top: 8px;

							@include pc() {
								height: 50px;
							}
						}
					}
				}
			}
		}

		// mail-tel
		&-mail-tel {
			color: $green;
			letter-spacing: 0.28em;

			@include sp() {
				font-size: 10px;
				margin-bottom: 80px;
			}

			&-message {
				@include sp() {
					font-size: 10px;
				}

				@include pc() {
					text-align: left;
				}
			}

			&-staff {
				margin: 55px 0 10px;

				@include sp() {
					img {
						width: 100%;
						transform: translate(5%, 0px);
					}
				}

				@include pc() {
					position: relative;

					img {
						width: 637px;
						position: absolute;
						right: 0;
						bottom: 0;
						transform: translate(-65px, 46px);
					}
				}
			}
		}
	}
}
