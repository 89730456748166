/*
---------------------------------------
community-work
---------------------------------------
*/

.pl_community-work {
	&--cover {
		@include sp() {
			background-image: url(../images/community-work/sp/cover.jpg);
		}

		@include pc() {
			background-image: url(../images/community-work/cover.jpg);
		}
	}

	&--list {
		@include sp() {
			background: #edecd9;
			padding: 85px 0;
			margin-top: 50px;
			position: relative;

			&::before,
			&::after {
				content: "";
				display: block;
				background: url(../images/community-work/sp/circle_parts.svg) no-repeat
					center bottom;
				background-size: contain;
				width: 218px;
				height: 32px;
				position: absolute;
				right: 0;
				left: 0;
				margin: 0 auto;
			}

			&::before {
				top: 0;
				transform: translate(0px, 53px);
			}

			&::after {
				bottom: 0;
				transform: translate(0px, -53px) rotate(180deg);
			}

			&-inner {
				width: 90%;
			}
		}

		@include pc() {
			&-inner {
				@include flex($ai: strech) {
					justify-content: space-between;
				}
				width: 1064px;
				position: relative;

				&::before,
				&::after {
					content: "";
					display: block;
					background: url(../images/community-work/circle_parts.svg) no-repeat
						center;
					background-size: contain;
					width: 68px;
					height: 240px;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto 0;
					z-index: -10;
				}

				&::before {
					left: 0;
					transform: translate(-61px, 0px);
				}

				&::after {
					right: 0;
					transform: translate(61px, 0px) rotate(180deg);
				}
			}
		}
	}

	&--item {
		background: #fff;
		position: relative;

		@include sp() {
			margin-top: 20px;
			padding: 35px 25px 25px;
			border-radius: 12px;

			&:first-child {
				margin-top: 0;
			}
		}

		@include pc() {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 512px;
			padding: 53px 43px;
			border: 1px solid $green;
			border-radius: 15px;

			&:nth-last-child(-n + 2) {
				margin-top: 40px;
			}
		}

		&::after {
			content: "";
			display: block;
			position: absolute;

			@include sp() {
				width: 210px;
				height: 20px;
				border-right: 2px solid $green;
				border-left: 2px solid $green;
				transform: translate(0px, -20px);
				top: 0;
				right: 0;
				left: 0;
				margin: 0 auto;
			}

			@include pc() {
				background: $green;
				width: 40px;
				height: 12px;
				top: 0;
				left: 0;
				transform: translate(-41px, 120px);
			}
		}

		&:first-child {
			&::after {
				display: none;
			}
		}

		@include pc() {
			&:nth-child(3) {
				&::after {
					display: none;
				}
			}
		}

		&::before {
			content: "";
			display: block;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			position: absolute;
			top: 0;
			right: 0;

			@include sp() {
				width: 66px;
				height: 66px;
				transform: translate(-25px, 14px);
			}

			@include pc() {
				width: 84px;
				height: 84px;
				transform: translate(-35px, 43px);
			}
		}

		&-boots {
			&::before {
				background-image: url(../images/community-work/boots.svg);
			}
		}

		&-shovel {
			&::before {
				background-image: url(../images/community-work/shovel.svg);

				@include sp() {
					transform: translate(-25px, 25px);
				}
			}
		}

		&-grass {
			&::before {
				background-image: url(../images/community-work/grass.svg);
			}
		}

		&-hose {
			&::before {
				background-image: url(../images/community-work/hose.svg);

				@include sp() {
					transform: translate(-25px, 25px);
				}
			}
		}

		&-heading {
			color: $green;

			span {
				@include din2014();
				letter-spacing: 0.01em;

				@include sp() {
					font-size: 10px;
				}

				@include pc() {
					font-size: 11px;
				}
			}

			h3 {
				@include serif();
				letter-spacing: 0.3em;

				@include sp() {
					font-size: 14px;
				}

				@include pc() {
					font-size: 22px;
					line-height: 1.82;
					margin-top: 3px;
				}
			}
		}

		&-body {
			@include sp() {
				line-height: 1.93;
				margin-top: 10px;
			}

			@include pc() {
				line-height: 2.29;
				margin-top: 20px;
			}
		}
	}

	&--images {
		@include sp() {
			padding-top: 50px;
		}

		@include pc() {
			padding-top: 120px;
		}

		&-list {
			@include flex($ai: flex-start) {
				justify-content: space-between;
			}

			img {
				width: 100%;
			}
		}

		&-item {
			margin-top: 5px;

			&:first-child {
				margin-top: 0;
			}

			@include sp() {
				width: 100%;
			}

			@include pc() {
				width: calc((100% - 10px) / 3);

				&:first-child {
					width: 100%;
				}
			}
		}

		@include sp() {
			&-kusakari-teoshi,
			&-kusakari {
				width: calc((100% - 5px) / 2);
			}
		}

		@include pc() {
			&-hakisouzi {
				order: 1;
			}

			&-kusakari-teoshi {
				order: 4;
			}

			&-kusakari {
				order: 3;
			}

			&-sansui {
				order: 2;
			}
		}
	}
}
