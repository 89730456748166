/*
---------------------------------------
form
---------------------------------------
*/

.m_form {
	::placeholder {
		color: #b3b3b3;
	}

	input,
	textarea {
		-webkit-appearance: none;
		width: 100%;
		background: #f2f2f2;
		border: 1px solid #f2f2f2;
		border-radius: 3px;

		@include sp() {
			padding: 9px 15px;
		}

		@include pc() {
			padding: 11px 23px;
		}
	}

	input[type="radio"],
	input[type="checkbox"] {
		display: none;
	}

	// radio
	&--radio {
		label {
			display: inline-block;
			margin: 0 10px 10px 0;

			&:last-child {
				margin: 0;
			}
		}

		span {
			position: relative;
			padding-left: 20px;

			&::before {
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				border: 1px solid #999;
				border-radius: 50%;
				position: absolute;
				top: 0;
				left: 0;
				transform: translate(0px, 4px);
			}
		}

		input {
			&:checked {
				& + span {
					color: #666;

					&::before {
						border: 1px solid #666;
						background: #666;
					}

					&::after {
						content: "";
						background: #fff;
						display: block;
						width: 5px;
						height: 5px;
						border-radius: 50%;
						position: absolute;
						top: 0;
						left: 0;
						transform: translate(5px, 9px);
					}
				}
			}
		}
	}

	// checkbox
	&--checkbox {
		label {
			display: inline-block;
			margin: 0 10px 10px 0;

			&:last-child {
				margin: 0;
			}
		}

		span {
			position: relative;
			padding-left: 20px;

			&::before {
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				border: 1px solid #999;
				position: absolute;
				top: 0;
				left: 0;
				transform: translate(0px, 4px);
				transition: all 300ms 0s ease;
			}
		}

		input {
			&:checked {
				& + span {
					color: #666;

					&::before {
						background: #666;
						border: 1px solid #666;
					}

					&::after {
						content: "";
						display: block;
						width: 4px;
						height: 8px;
						border-bottom: 2px solid #fff;
						border-right: 2px solid #fff;
						position: absolute;
						top: 0;
						left: 0;
						transform: translate(5px, 7px) rotate(40deg);
					}
				}
			}
		}
	}

	// select
	select {
		position: relative;
		z-index: 1;
		width: 100%;
		padding: 7px;
		border: transparent;
		border-radius: 0;
		background: transparent;
	}

	&--select {
		position: relative;
		display: inline-block;
		width: 100%;
		border: 1px solid #f2f2f2;

		// arrow up + down
		&::before,
		&::after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 5px 0 5px;
			border-color: #f2f2f2 transparent transparent transparent;
			margin: auto 0;
			position: absolute;
			right: 0;
			z-index: 0;
		}

		&::before {
			top: 0;
			transform: translate(-8px, 10px) rotate(180deg);
		}

		&::after {
			bottom: 0;
			transform: translate(-8px, -10px);
		}

		//  background + arrow-down
		// 	&::before {
		// 		content: "";
		// 		background: #F2F2F2;
		// 		width: 28px;
		// 		height: 100%;
		// 		position: absolute;
		// 		top: 0;
		// 		right: 0;
		// 		z-index: 0;
		// 	}

		// 	&::after {
		// 		content: "";
		// 		width: 0;
		// 		height: 0;
		// 		margin: auto 0;
		// 		border-style: solid;
		// 		border-width: 5px 5px 0 5px;
		// 		border-color: #fff transparent transparent transparent;
		// 		position: absolute;
		// 		top: 0;
		// 		right: 0;
		// 		bottom: 0;
		// 		z-index: 0;
		// 		transform: translate(-8px, 0px);
		//     }
	}

	// input-file
	&--file {
		input[type="file"] {
			border: none;
			font-size: 12px;
			font-size: 1.2rem;
		}
	}

	// submit
	&--submit {
		input {
			@include transition();
			color: #fff;
			background: #666;
			border: 1px solid #666;
			padding: 15px;
		}
	}

	// requi$red
	&--required {
		&::after {
			color: $red;
			display: inline-block;
			content: "※";
			transform: translate(-5px, 0px);
		}
	}

	// error
	.error {
		color: $red;
	}
}
