/*
---------------------------------------
about
---------------------------------------
*/

.pl_about {
	&--cover {
		@include sp() {
			background-image: url(../images/about/sp/cover.jpg);
		}

		@include pc() {
			background-image: url(../images/about/cover.jpg);
		}
	}

	&--about {
		position: relative;

		@include sp() {
			padding-bottom: 20px;
		}

		@include pc() {
			padding: 20px 0 75px;
		}

		&::after {
			content: "";
			display: block;
			width: 100%;
			background: $green;
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;

			@include sp() {
				height: 100px;
			}

			@include pc() {
				height: 300px;
			}
		}

		@include pc() {
			&-list {
				@include flex() {
					justify-content: center;
					flex-direction: row-reverse;
				}
			}
		}

		&-item {
			@include sp() {
				margin-top: 50px;
			}

			@include pc() {
				writing-mode: vertical-rl;
				margin-right: 90px;

				span {
					-webkit-text-combine: horizontal;
					-ms-text-combine-horizontal: all;
					text-combine-upright: all;
					letter-spacing: 0;
				}
			}

			&:first-child {
				@include sp() {
					margin-top: 0;
				}

				@include pc() {
					margin-right: 0;
				}
			}
		}

		&-heading {
			@include serif();
			color: $green;
			letter-spacing: 0.37em;
			font-weight: 600;

			@include sp() {
				font-size: 16px;
				line-height: 1.56;
			}

			@include pc() {
				font-size: 24px;
				line-height: 1.63;
			}
		}

		&-body {
			@include sp() {
				line-height: 1.93;
				margin-top: 30px;
			}

			@include pc() {
				margin-right: 35px;
				letter-spacing: 0.24em;
				line-height: 2.5;
				padding-top: 15px;
			}
		}

		&-image {
			@include sp() {
				margin-top: 30px;
			}

			@include pc() {
				margin-top: 40px;
			}

			img {
				width: 100%;

				@include pc() {
					border-radius: 20px;
				}
			}
		}
	}

	&--strength {
		@include sp() {
			padding-top: 80px;
		}

		@include pc() {
			padding-bottom: 80px;
			overflow-x: hidden;
		}

		&-item {
			$parent: &;

			@include sp() {
				margin-top: 50px;
			}

			@include pc() {
				@include flex($ai: flex-end) {
					justify-content: flex-start;
				}
				margin-top: 156px;
				position: relative;

				&::after {
					content: "";
					display: block;
					background: #f6faf1;
					position: absolute;
					bottom: 0;
					transform: translate(0px, 75px);
					z-index: -1;
				}

				&-image {
					width: 620px;
				}

				&:nth-child(2n - 1) {
					&::after {
						width: 854px;
						height: 305px;
						border-radius: 20px;
						right: 0;
					}

					#{$parent}-image {
						margin-right: 75px;
					}

					#{$parent}-contents {
						width: 455px;
					}
				}

				&:nth-child(2n) {
					flex-direction: row-reverse;
					justify-content: flex-end;

					&::after {
						width: 150vw;
						height: 296px;
						left: 0;
						transform: translate(-25vw, 75px);
					}

					#{$parent}-image {
						margin-right: 0;
					}

					#{$parent}-contents {
						width: 513px;
						margin-right: 65px;
					}
				}

				&:nth-child(3) {
					&::after {
						height: 359px;
					}
				}
			}

			&:first-child {
				@include sp() {
					margin-top: 0;
				}

				@include pc() {
					margin-top: 65px;
				}
			}

			&-image {
				@include sp() {
					width: 100%;
				}

				img {
					width: 100%;
				}
			}
		}

		&-heading {
			color: $green;

			@include sp() {
				margin-top: 35px;
			}

			&-number {
				@include din2014();

				@include sp() {
					font-size: 10px;
					letter-spacing: 0.1em;
				}

				@include pc() {
					font-size: 11px;
					letter-spacing: 0.1em;
				}

				span {
					@include sp() {
						font-size: 16px;
						margin-left: 3px;
					}

					@include pc() {
						font-size: 28px;
						margin-left: 10px;
					}
				}
			}

			h2 {
				@include serif();
				font-weight: 500;

				@include sp() {
					font-size: 14px;
					letter-spacing: 0.3em;
				}

				@include pc() {
					font-size: 23px;
					line-height: 1.82;
					letter-spacing: 0.37em;
					margin-top: 5px;
				}
			}
		}

		&-body {
			@include sp() {
				line-height: 1.93;
				margin-top: 20px;
			}

			@include pc() {
				line-height: 2.29;
				margin-top: 20px;
			}
		}
	}
}
