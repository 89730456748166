/*
---------------------------------------
index
---------------------------------------
*/

.pl_index {
	//COVER
	&--cover {
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: calc(100vh - 120px);
		position: relative;

		@include sp() {
			background-image: url(../images/index/sp/cover.jpg);
			background-position: center top -120px;
		}

		@include pc() {
			background-image: url(../images/index/cover.jpg);
			background-position: center;
		}

		&-title {
			color: $green;
			font-weight: 700;
			letter-spacing: 0.36em;
			position: absolute;

			@include sp() {
				font-size: 20px;
				line-height: 1.45;
				bottom: 0;
				left: 0;
				transform: translate(10%, -83px);
			}

			@include pc() {
				font-size: 35px;
				line-height: 1.9;
				top: 0;
				right: 0;
				transform: translate(-20%, 100px);
			}
		}
	}

	// INTRO
	&--intro {
		color: $green;
		font-weight: 700;

		@include sp() {
			background: #edecd9;
			letter-spacing: 0.12em;
			padding: 60px 0;
		}

		@include pc() {
			background: url(../images/index/bg_intro.svg) no-repeat center bottom;
			background-size: 100% 383px;
			letter-spacing: 0.2em;
			padding: 102px 0 0;
		}

		h1 {
			text-align: center;

			img {
				@include sp() {
					width: 338px;
					height: 41px;
				}

				@include pc() {
					width: 739px;
					height: 51px;
				}
			}
		}

		&-point {
			margin: 0 auto;

			@include sp() {
				width: 85%;
				transform: translate(3%, 0);
			}

			@include pc() {
				@include flex($ai: center) {
					justify-content: space-between;
				}
				width: 1040px;
				margin-top: 75px;
			}

			&-item {
				background: #fff;
				position: relative;

				@include sp() {
					padding: 23px 10px 23px 73px;
					border-radius: 5px;
					margin-top: 30px;
				}

				@include pc() {
					text-align: center;
					width: 500px;
					padding: 30px;
				}

				&::after {
					content: "";
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					display: block;
					position: absolute;
					top: 0;
					left: 0;

					@include sp() {
						width: 85px;
						height: 85px;
						transform: translate(-24px, -17px);
					}

					@include pc() {
						width: 95px;
						height: 95px;
						transform: translate(-24px, -17px);
					}
				}
			}

			&-price {
				&::after {
					background-image: url(../images/index/price.svg);
				}

				@include pc() {
					h2 {
						font-size: 34px;
					}
				}
			}

			&-worries {
				&::after {
					background-image: url(../images/index/worries.svg);
				}
			}

			&-heading {
				font-weight: 700;

				@include pc() {
					font-size: 28px;
				}
			}

			&-alt {
				color: $red;
				letter-spacing: 0.34em;

				@include sp() {
					font-size: 16px;
				}

				@include pc() {
					font-size: 22px;
				}
			}
		}

		&-mail-tel {
			@include sp() {
				margin-top: 33px;
			}

			@include pc() {
				margin-top: 20px;
			}

			& > div {
				&::after {
					background-image: url(../images/common/staff.svg);

					@include sp() {
						width: 103px;
						height: 122px;
					}

					@include pc() {
						width: 135px;
						height: 161px;
						transform: translate(0px, 21px);
					}
				}
			}
		}
	}

	// ABOUT
	&--about {
		@include sp() {
			margin-top: 60px;
			padding-top: 60px;
			background: linear-gradient(
				to bottom,
				rgba(218, 223, 217, 0.5),
				rgba(218, 223, 217, 0)
			);
		}

		@include pc() {
			padding: 130px 0 80px;
			background: linear-gradient(
				to top,
				rgba(218, 223, 217, 0.5),
				rgba(218, 223, 217, 0)
			);
			position: relative;

			&::before {
				content: "";
				background: url(../images/index/about.jpg) no-repeat center;
				background-size: cover;
				display: block;
				width: 42.9%;
				height: 610px;
				border-top-right-radius: 305px;
				border-bottom-right-radius: 305px;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 10;
			}

			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 2px;
				background: $d_green;
				position: absolute;
				top: 0;
				left: 0;
				transform: translate(0px, 225px);
			}

			&-inner {
				@include inner();
				padding-left: 550px;
			}
		}

		&-heading {
			@include sp() {
				&::after {
					content: "";
					background: url(../images/index/about.jpg) no-repeat center;
					background-size: cover;
					display: block;
					width: 100%;
					height: 175px;
					margin-top: 15px;
				}
			}

			@include pc() {
				text-align: left;
			}
		}

		&-contents {
			@include sp() {
				@include inner($width: $inner_width_sp);
			}

			&-heading {
				@include serif();
				color: $green;
				font-weight: 600;
				letter-spacing: 0.37em;

				@include sp() {
					font-size: 16px;
				}

				@include pc() {
					font-size: 20px;
					line-height: 2.25;
					margin-top: 50px;
				}
			}

			p {
				margin-top: 30px;

				@include sp() {
					line-height: 1.92;
				}

				@include pc() {
					line-height: 2.29;
				}
			}
		}
	}
}
