/*
---------------------------------------
ass
---------------------------------------
*/

.pl_ass {
	&--cover {
		@include sp() {
			background-image: url(../images/ass/sp/cover.jpg);
		}

		@include pc() {
			background-image: url(../images/ass/cover.jpg);
		}
	}

	&--price {
		&-list {
			color: #384635;
			text-align: center;
			border: 1px solid #384635;

			@include sp() {
				margin-top: 55px;
			}

			@include pc() {
				@include flex($ai: strech) {
					justify-content: space-between;
				}
				width: 1040px;
				margin: 80px auto 0;
			}

			& > div {
				@include sp() {
					border-bottom: 1px solid #384635;

					&:last-child {
						border-bottom: none;
					}
				}

				@include pc() {
					width: calc(100% / 3);
					border-right: 1px solid #384635;

					&:last-child {
						border-right: none;
					}
				}
			}

			dt {
				background: #dbe0da;

				@include sp() {
					padding: 11px;
				}

				@include pc() {
					padding: 23px;
				}

				h3 {
					font-size: 17px;
					font-weight: 500;
				}

				span {
					@include sp() {
						font-size: 20px;
					}

					@include pc() {
						font-size: 24px;
					}
				}
			}

			dd {
				letter-spacing: 0.06em;

				@include sp() {
					font-size: 27px;
					padding: 10px;
				}

				@include pc() {
					font-size: 30px;
					padding: 18px;
				}
			}
		}

		&-yen {
			letter-spacing: 0;
			@include sp() {
				font-size: 22px;
				margin-right: 3px;
			}

			@include pc() {
				font-size: 23px;
				margin-right: 10px;
			}
		}

		&-or-more {
			letter-spacing: 0;

			@include pc() {
				font-size: 21px;
				margin-left: 3px;
			}
		}

		&-notice {
			@include sp() {
				margin-top: 30px;
			}

			@include pc() {
				@include flex($ai: flex-start) {
					justify-content: space-between;
				}
				width: 1010px;
				margin: 40px auto 0;

				&-text {
					width: 626px;
				}
			}

			h2 {
				font-size: 16px;

				&::before {
					content: "";
					display: inline-block;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background: #000;
				}
			}

			ul {
				letter-spacing: 0.1em;
				line-height: 1.93;

				@include sp() {
					margin-top: 5px;
				}

				@include pc() {
					margin-top: 10px;
				}
			}

			&-image {
				@include sp() {
					margin-top: 20px;
				}

				@include pc() {
					width: 340px;
				}

				img {
					width: 100%;
				}
			}
		}

		&-mail-tel {
			color: $green;
			letter-spacing: 0.28em;

			@include sp() {
				font-size: 10px;
				margin-bottom: 80px;
			}

			&-message {
				@include sp() {
					font-size: 10px;
				}

				@include pc() {
					text-align: left;
				}
			}

			&-staff {
				margin: 160px 0 10px;

				@include pc() {
					position: relative;

					img {
						width: 777px;
						position: absolute;
						right: 0;
						bottom: 0;
						transform: translate(-65px, 46px);
					}
				}
			}
		}
	}
}
