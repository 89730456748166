/*
---------------------------------------
mail_tel
---------------------------------------
*/

.m_mail_tel {
	@include sp() {
		@include inner($width: $inner_width_sp);
		margin-top: 30px;
	}

	@include pc() {
		margin-top: 20px;
	}

	&--btn-wrapper {
		color: $green;

		@include sp() {
			& > * {
				width: 100%;
			}
		}

		@include pc() {
			@include flex();
			text-align: center;

			& > * {
				display: flex;
				align-items: baseline;
				justify-content: center;
				width: 50%;
				padding: 32px 0;
			}
		}
	}

	&--mail {
		color: #fff;
		background: $green;
		font-weight: bold;
		align-items: center;
		letter-spacing: 0.3em;

		@include sp() {
			@include button();
			font-size: 16px;

			img {
				width: 25px;
				height: 25px;
				margin-right: 8px;
			}
		}

		@include pc() {
			font-size: 25px;

			img {
				width: 40px;
				height: 40px;
				margin-right: 20px;
				transform: translate(0px, 2px);
			}
		}
	}

	&--tel {
		@include din;
		color: #fff;
		letter-spacing: 0.08em;

		@include sp() {
			@include button($bg: #aaa246);
			align-items: baseline;
			border-color: #aaa246;
			margin-top: 10px;
		}

		@include pc() {
			background: #aaa246;
			font-size: 24px;
		}

		span {
			@include sp() {
				font-size: 23px;
				margin-left: 3px;
			}

			@include pc() {
				font-size: 42px;
				margin-left: 10px;
			}
		}
	}

	&--message {
		font-weight: 500;
		letter-spacing: 0.28em;
		position: relative;

		@include sp() {
			font-size: 15px;
		}

		@include pc() {
			font-size: 18px;
			text-align: center;
		}

		&::after {
			content: "";
			display: block;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}
