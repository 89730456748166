.m_banner {
	text-align: center;
	position: fixed;
	bottom: 0;
	z-index: 1000;

	&--wrapper {
		@include flex($ai: center);
	}

	&--bg-yellow {
		background: #fcee21;
	}

	&--bg-d-green {
		background: #384635;
	}

	&--bg-gray {
		background: #78827e;
	}

	&--mail {
		color: #fff;
	}

	// SP
	@include sp() {
		width: 100%;

		&--wrapper {
			& > * {
				height: 60px;
				padding: 11px 0 10px;
			}
		}

		&--message {
			color: #fff;
			font-size: 15px;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 61.3%;
			border-top-left-radius: 20px;
		}

		&--mail {
			width: 19.35%;
		}

		&--tel {
			width: 19.35%;
			border-top-right-radius: 20px;
		}
	}

	// PC
	@include pc() {
		right: 0;
		left: 0;
		width: 100%;
		margin: 0 auto;
		overflow-x: scroll;

		&--wrapper {
			min-width: 1440px;

			& > * {
				@include flex($ai: center) {
					justify-content: center;
				}
				line-height: 1.4;
				height: 65px;
				padding: 12px 20px 11px;
			}
		}

		&--message-first {
			font-size: 18px;
			letter-spacing: 0.52em;
			width: 27.8%;
			position: relative;

			&::after {
				content: "";
				background: url(../images/common/banner/ornament_arrow_r_yellow.svg);
				display: block;
				width: 11px;
				height: 65px;
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(11px, 0px);
				z-index: 5;
			}

			img {
				margin-right: 17px;
			}
		}

		&--message-second {
			color: #fff;
			font-size: 20px;
			letter-spacing: 0.5em;
			width: 51.3%;
			position: relative;

			&::after {
				content: "";
				background: url(../images/common/banner/ornament_arrow_r_green.svg);
				display: block;
				width: 11px;
				height: 65px;
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(11px, 0px);
				z-index: 5;
			}

			span {
				font-size: 10px;
				letter-spacing: 0.26em;
				line-height: 1.6;
				text-align: left;
				margin-left: 5%;
				transform: translate(0px, 2px);
			}
		}

		&--mail {
			font-size: 16px;
			font-weight: bold;
			width: 20.9%;

			img {
				margin-right: 13px;
			}
		}
	}
}
