.l_content {
	&--list {
		@include flex();
	}

	&--item {
		color: #fff;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		&:hover {
			opacity: 1;
		}
	}

	&--heading {
		@include din();
		@include flex($ai: center) {
			flex-direction: column;
			justify-content: center;
		}
		height: 100%;

		span {
			display: block;
			font-weight: 600;
			letter-spacing: 0.24em;
		}
	}

	&--sand-sale {
		background-image: url(../images/common/content/sand_sale.jpg);
	}

	&--sat-acceptance {
		background-image: url(../images/common/content/sat_acceptance.jpg);
	}

	&--company {
		background-image: url(../images/common/content/company.jpg);
	}

	// SP
	@include sp() {
		padding-top: 85px;

		&--list {
			flex-direction: column;
		}

		&--item {
			width: 100%;
			height: 175px;
			margin-top: 10px;

			&:first-child {
				margin-top: 0;
			}
		}

		&--heading {
			font-size: 35px;

			span {
				font-size: 17px;
			}
		}
	}

	// PC
	@include pc() {
		padding-top: 110px;

		&--item {
			width: 50%;
			height: 320px;

			&:last-child {
				width: 100%;
				margin-top: 20px;
			}
		}

		&--heading {
			font-size: 41px;

			span {
				font-size: 18px;
			}

			&::after {
				@include pseudoBgImg() {
					background-image: url(../images/common/arrow_d.svg);
					width: 18px;
					height: 11px;
					margin-top: 10px;
				}
				@include transition();
			}

			&:hover {
				&::after {
					transform: translate(0px, 15px);
				}
			}
		}
	}
}
