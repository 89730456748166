/*
---------------------------------------
mixin
---------------------------------------
*/

@mixin pseudoBgImg($display: block) {
	content: "";
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	display: $display;
	@content;
}

@mixin btn($b-color: #fff, $b-radius: 4px, $after-w: 20px, $after-h: 20px) {
	@include flex($ai: center) {
		justify-content: center;
	}
	border: 1px solid $b-color;
	border-radius: $b-radius;
	position: relative;
	@content;

	&::after {
		content: "";
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		display: block;
		width: $after-w;
		height: $after-h;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
}

@mixin flex($ai: strech) {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-direction: row;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-line-pack: center;
	align-content: center;
	-ms-flex-align: $ai;
	-webkit-box-align: $ai;
	align-items: $ai;
	@content;
}

@mixin hover() {
	opacity: 0.6;
	filter: alpha(opacity=60);
	-ms-filter: "alpha( opacity=60 )";
}

@mixin inner($width: $inner_width) {
	width: $width;
	margin: 0 auto;
}

@mixin transition() {
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

// Hamburger Menu Animation

@mixin keyframes($animation-name: animation) {
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation(
	$name: name,
	$duration: 0.5s,
	$timing-function: ease-in-out,
	$fill-mode: forwards
) {
	animation: $name $duration $timing-function $fill-mode;
}

// Fonts
@mixin min() {
	font-family: dnp-shuei-mincho-pr6n, sans-serif;
	font-weight: 500;
	font-style: normal;
}

@mixin din() {
	font-family: din-condensed, sans-serif;
	font-weight: 400;
	font-style: normal;
}

@mixin din2014() {
	font-family: din-2014-narrow, sans-serif;
	font-weight: 400;
	font-style: normal;
}

@mixin midashigo() {
	font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
	font-weight: 600;
	font-style: normal;
}

@mixin serif() {
	font-family: "Noto Serif JP", serif;
}

// Button
@mixin button($bg: $green) {
	@include flex($ai: center) {
		justify-content: center;
	}
	color: #fff;
	background: $bg;
	border: 3px solid $green;
	padding: 0 15px;

	@include sp() {
		height: 56px;
		border-radius: 28px;
	}

	@include pc() {
		height: 60px;
		border-radius: 30px;
	}
	@content;

	&:hover {
		color: $green;
		background: transparent;
	}
}
