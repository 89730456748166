/*
---------------------------------------
cover
---------------------------------------
*/

.p_cover {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;

	@include sp() {
		height: 160px;
	}

	@include pc() {
		height: 200px;
	}
}
