/*
---------------------------------------
machine
---------------------------------------
*/

.l_machine {
	@include pc() {
		background: $d_green;
		padding-bottom: 50px;
	}

	&--heading {
		color: #fff;
		background: $d_green;
		font-weight: 500;
		text-align: center;

		@include sp() {
			font-size: 14px;
			letter-spacing: 0.34em;
			padding: 20px;
		}

		@include pc() {
			font-size: 17px;
			letter-spacing: 0.69em;
			padding: 13px;
		}
	}

	&--list {
		@include sp() {
			@include inner($width: $inner_width_sp);
		}

		@include pc() {
			@include flex($ai: strech) {
				justify-content: space-between;
			}
		}
	}

	&--item {
		@include sp() {
			margin-top: 40px;
		}

		@include pc() {
			width: calc(100% / 3);
			position: relative;
		}

		img {
			width: 100%;
		}
	}

	&--name {
		font-size: 14px;

		@include sp() {
			margin-top: 10px;
		}

		@include pc() {
			font-weight: 700;
			letter-spacing: 0.14em;
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(25px, 20px);
			text-shadow: #fff 0 0 4px;
		}
	}
}
