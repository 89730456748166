// BREAKEPOINTS

@mixin max_screen($break_point) {
	@media screen and (max-width: $break_point) {
		@content;
	}
}

@mixin min_screen($break_point) {
	@media screen and (min-width: $break_point) {
		@content;
	}
}

@mixin screen($break_point_min, $break_point_max) {
	@media screen and (min-width: $break_point_min) and (max-width: $break_point_max) {
		@content;
	}
}

@mixin sp() {
	@media screen and (max-width: $SP) {
		@content;
	}
}

@mixin tab() {
	@media screen and (min-width: $SP+1) {
		@content;
	}
}

@mixin sp_tab() {
	@media screen and (max-width: $TAB) {
		@content;
	}
}

@mixin pc() {
	@media screen and (min-width: $PC) {
		@content;
	}
}
