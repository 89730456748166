/*
---------------------------------------
contact
---------------------------------------
*/

.pl_contact {
	&--cover {
		@include sp() {
			background-image: url(../images/contact/sp/cover.jpg);
		}

		@include pc() {
			background-image: url(../images/contact/cover.jpg);
		}
	}

	@include pc() {
		// index
		&--form {
			padding-bottom: 75px;

			dt {
				transform: translate(0px, 12px);
			}
		}

		&--inner {
			width: 850px;
		}
	}

	&--summary {
		font-size: 12px;
		letter-spacing: 0.22em;

		@include sp() {
			line-height: 1.83;
		}

		@include pc() {
			text-align: center;
			margin-top: 50px;
		}

		p {
			@include sp() {
				margin-top: 30px;

				&:first-child {
					text-align: center;
					margin-top: 0;
				}
			}

			@include pc() {
				line-height: 2.41;
				margin-top: 20px;
			}
		}

		a {
			@include din();
			letter-spacing: 0.04em;

			@include sp() {
				@include button() {
					background: #aba346;
					border-color: #aba346;
				}
				margin-top: 20px;
			}

			@include pc() {
				color: $red;
				font-size: 34px;
				display: inline-block;
				margin-top: 25px;
			}

			span {
				letter-spacing: 0.08em;

				@include sp() {
					font-size: 23px;
				}

				@include pc() {
					font-size: 55px;
					margin-left: 5px;
				}
			}
		}
	}

	&--list {
		@include sp() {
			margin-top: 30px;
		}

		@include pc() {
			margin-top: 110px;
		}
	}

	&--item {
		@include flex($ai: flex-start) {
			justify-content: space-between;
		}

		@include sp() {
			margin-top: 20px;
		}

		@include pc() {
			margin-top: 30px;
		}

		&:first-child {
			margin-top: 0;
		}

		dt {
			@include sp() {
				width: 100%;
			}

			@include pc() {
				width: 200px;
			}
		}

		dd {
			@include sp() {
				width: 100%;
				margin-top: 6px;
			}

			@include pc() {
				width: calc(100% - 200px);
			}
		}
	}

	&--notes {
		color: $red;
		font-size: 12px;
		margin-top: 15px;

		@include pc() {
			padding-left: 200px;
		}
	}

	&--submit {
		@include sp() {
			margin-top: 15px;
		}

		@include pc() {
			padding-left: 200px;
			margin-top: 30px;
		}

		input {
			@include button() {
				font-size: 16px;
				font-weight: 700;
				letter-spacing: 0.3em;

				@include pc() {
					width: 220px;
				}
			}
		}
	}

	// confirm
	&--confirm {
		$parent: &;

		h1 {
			color: $green;
		}

		&-submit {
			@include pc() {
				@include flex($ai: center) {
					justify-content: space-between;
				}
			}

			#{$parent}-back {
				color: #666;
				background: #ccc;
				border-color: #ccc;
			}

			input {
				@include sp() {
					margin-top: 15px;

					&:first-child {
						margin-top: 0;
					}
				}

				@include pc() {
					width: 45%;
				}
			}
		}
	}

	// complete
	&--complete {
		@include pc() {
			text-align: center;
		}

		h1 {
			color: $green;
		}

		&-contents {
			margin-top: 30px;

			span {
				display: inline-block;
			}

			a {
				text-decoration: underline;
			}
		}
	}

	// error
	&--error {
		@include pc() {
			text-align: center;
		}

		&-contents {
			margin-top: 30px;

			span {
				display: inline-block;
			}

			a {
				text-decoration: underline;
			}
		}
	}
}
