/*
---------------------------------------
company
---------------------------------------
*/

.pl_company {
	&--cover {
		@include sp() {
			background-image: url(../images/company/sp/cover.jpg);
		}

		@include pc() {
			background-image: url(../images/company/cover.jpg);
		}
	}

	// GREETING
	&--greeting {
		@include pc() {
			padding: 130px 0 80px;
			background: linear-gradient(
				to top,
				rgba(218, 223, 217, 0.5),
				rgba(218, 223, 217, 0)
			);
			position: relative;

			&::before {
				content: "";
				background: url(../images/company/kamiya.png) no-repeat top;
				background-size: cover;
				display: block;
				width: 34.7%;
				height: 550px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 10;
				transform: translate(0px, 35px);
			}
		}

		&-inner {
			@include sp() {
				@include inner($width: $inner_width_sp);
				position: relative;

				&::before {
					content: "";
					background: url(../images/company/sp/kamiya.jpg) no-repeat top;
					background-size: cover;
					display: block;
					width: 100%;
					height: 270px;
				}
			}

			@include pc() {
				@include inner($width: 1040px);
				padding-left: 410px;
			}
		}

		&-heading {
			@include serif();
			color: $green;
			letter-spacing: 0.37em;

			@include sp() {
				font-size: 16px;
				margin-top: 30px;
			}

			@include pc() {
				font-size: 22px;
			}
		}

		&-body {
			@include sp() {
				line-height: 1.92;
				margin-top: 20px;
			}

			@include pc() {
				line-height: 2.29;
				margin-top: 30px;
			}
		}

		&-sign {
			margin-top: 20px;

			@include sp() {
				font-size: 12px;
				text-align: right;
			}

			span {
				font-weight: 500;

				@include sp() {
					font-size: 14px;
				}

				@include pc() {
					font-size: 16px;
				}
			}
		}
	}

	// INFORMATION
	&--information {
		letter-spacing: 0.17em;

		@include sp() {
			padding-top: 90px;
		}

		@include pc() {
			&-inner {
				width: 854px;
			}
		}

		dl {
			& > div {
				border-top: 1px solid #ccc;

				@include sp() {
					padding: 15px 0;
				}

				@include pc() {
					display: flex;
					padding: 25px 0;
				}

				&:last-child {
					border-bottom: 1px solid #ccc;
				}
			}
		}

		h2 {
			color: $green;

			@include sp() {
				font-size: 14px;
				font-weight: 500;
			}

			@include pc() {
				font-size: 16px;
				font-weight: 700;
			}
		}

		@include pc() {
			dt {
				width: 170px;
			}
		}

		dd {
			@include sp() {
				margin-top: 3px;
			}

			@include pc() {
				font-size: 16px;
				letter-spacing: 0.22em;
				width: calc(100% - 170px);
			}
		}
	}
}
