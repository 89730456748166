/*
---------------------------------------
variables
---------------------------------------
*/

// $font_family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Noto Sans Japanese", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
$font-family: "Noto Sans JP", sans-serif;
$font_size: 14px;
$font_size_rem: 1.4rem;
$letter_spacing: 0.2em;
$line_height: normal;

$text_color: #000;

$inner_width: 1200px;
$inner_width_sp: 80%;

// WIDTH:
// $PC: 1280px;
// $SP: 414px;
$PC: 841px;
$SP: 840px;
$TAB: 840px;

// COLOR
$green: #4a6445;
$d_green: #2a3528;
$l_gray: #f6faf1;
$red: #c0272d;
$yellow: #fcee21;

// HAMBURGER MENU
$navi_button_width: 22px;
$hamburger_line_color: $green;
$hamburger_line_height: 2px;
$second_line_margin_top_bottom: 6px; //Navi height

$translateY: $hamburger_line_height + $second_line_margin_top_bottom;
$navi_height: $hamburger_line_height * 3 + $second_line_margin_top_bottom * 2;
