/*
---------------------------------------
base
---------------------------------------
*/

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	// font-size: 14px;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $font_family;
	font-size: $font_size;
	font-size: $font_size_rem;
	font-weight: 400;
	letter-spacing: $letter_spacing;
	line-height: $line_height;

	@include sp() {
		min-width: 320px;
		padding: 60px 0;
	}

	@include pc() {
		padding: 55px 0 112px;
		min-width: 1440px;
	}
}

.detect-pc {
	min-width: $PC;
}

.detect-sp {
	min-width: $SP;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

a {
	@include transition();
	color: $text_color;
	text-decoration: none;

	&:hover {
		@include hover();
	}
}

img {
	vertical-align: bottom;
}

li {
	list-style: none;
}

.defs {
	display: none;
}

.visually-hidden {
	position: absolute;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(0px 0px 99.9% 99.9%);
	clip-path: inset(0px 0px 99.9% 99.9%);
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
}

.antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.no-action {
	pointer-events: none;
	cursor: default;
}

.wf-loading {
	visibility: hidden;
}

.wf-active {
	visibility: visible;
}

@include sp() {
	.pc-only {
		display: none;
	}
}

@include pc() {
	.sp-only {
		display: none;
	}
}

.inner {
	@include sp() {
		@include inner($width: $inner_width_sp);
	}

	@include pc() {
		@include inner();
	}
}

// iframe関連の表示

.iframe-outer {
	position: relative;
}

.iframe-wrapper {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	/* 100 * 9/16 */
}

.iframe-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
}
