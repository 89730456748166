/*
---------------------------------------
utilis
---------------------------------------
*/

.p_utilis {

    &--arrow {

        &--next {

            &::after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 9px;
                height: 15px;
                background: url(/images/common/arrow_next.svg) no-repeat center;
                background-size: contain;
                transform: translate(2px, -1px);
            }
        }

        &--prev {

            &::before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 9px;
                height: 15px;
                background: url(/images/common/arrow_prev.svg) no-repeat center;
                background-size: contain;
                transform: translate(-2px, -1px);
            }
        }
    }

    &--category {

        ul {
            @include flex($ai: center) {
                justify-content: flex-start;
            }
        }

        li {
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
        }

        .current {
            color: $text_color;
            padding-bottom: 5px;
            border-bottom: 1px solid $text_color;
        }
    }
}
