/*
---------------------------------------
contents
---------------------------------------
*/

.l_contents {
	&--section {
		@include sp() {
			padding-top: 35px;
		}

		@include pc() {
			padding-top: 70px;
		}

		&-summary {
			@include sp() {
				line-height: 1.93;
				margin-top: 30px;
			}

			@include pc() {
				line-height: 2.29;
				text-align: center;
				margin-top: 40px;
			}
		}
	}
}
