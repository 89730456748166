/*
---------------------------------------
error_404
---------------------------------------
*/

.pl_error_404 {
	font-size: 14px;
	font-size: 1.4rem;
	padding: 50px 30px;

	@include pc() {
		text-align: center;
	}

	h1 {
		font-size: 16px;
		font-size: 1.6rem;
		font-weight: bold;
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 15px;
	}

	a {
		font-weight: bold;
	}
}
