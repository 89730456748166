/*
---------------------------------------
btn
---------------------------------------
*/

.p_btn {
	&--view-more,
	&--submit {
		@include button() {
			@include midashigo();

			@include sp() {
				font-size: 16px;
				margin-top: 30px;
			}

			@include pc() {
				font-size: 16px;
				width: 250px;
				margin-top: 40px;
			}
		}
	}

	&--contact {
		@include button() {
			font-weight: 500;
			letter-spacing: 0.44em;

			@include pc() {
				width: 220px;
			}
		}
	}
}

.p_btn--hamburger {
	@include pc() {
		display: none;
	}

	width: $navi_button_width;
	position: relative;
	z-index: 100;
	cursor: pointer;

	div {
		background: $hamburger_line_color;
		width: 100%;
		height: $hamburger_line_height;

		&:nth-child(2) {
			margin: $second_line_margin_top_bottom 0px;
			position: relative;

			&::after {
				background: $hamburger_line_color;
				content: "";
				display: block;
				width: 100%;
				height: $hamburger_line_height;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
			}
		}
	}

	&.open {
		div:first-child {
			@include animation(naviLineFirstOpen);
		}

		div:nth-child(2) {
			@include animation(naviLineSecondOpen);

			&::after {
				@include animation(naviLineSecondAfterOpen);
			}
		}

		div:last-child {
			@include animation(naviLineLastOpen);
		}
	}

	&.close {
		div:first-child {
			@include animation(naviLineFirstClose);
		}

		div:nth-child(2) {
			@include animation(naviLineSecondClose);

			&::after {
				@include animation(naviLineSecondAfterClose);
			}
		}

		div:last-child {
			@include animation(naviLineLastClose);
		}
	}
}
