/*
---------------------------------------
header
---------------------------------------
*/

.l_header {
	background: rgba(255, 255, 255, 1);
	position: fixed;
	width: 100%;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1000;
	overflow-x: scroll;

	@include sp() {
		&--inner {
			width: 90%;
		}

		&--head-logo {
			display: flex;
			align-items: center;

			img {
				width: 145px;
				height: 15px;
				vertical-align: bottom;
			}
		}

		&--logo-btn-wrapper {
			@include flex($ai: center) {
				justify-content: space-between;
			}
			padding: 22px 0 19px;
		}

		&--sns-btn {
			@include flex($ai: center) {
				justify-content: flex-start;
			}
		}

		&--sns {
			@include flex($ai: center) {
				justify-content: flex-start;
			}
			margin-right: 16px;

			a {
				@include flex($ai: center) {
					justify-content: center;
				}

				&:first-child {
					margin-right: 10px;
				}
			}

			img {
				width: 19px;
				height: 19px;
			}
		}

		&--navi {
			visibility: hidden;
			width: 100vw;
			height: 0;
			overflow: hidden;
			-webkit-transition: all 0.45s ease-in-out;
			transition: all 0.45s ease-in-out;
			background: transparent
				radial-gradient(closest-side at 50% 50%, #4a6445 32%, #42503d 90%) 0% 0%
				no-repeat padding-box;

			&.open {
				visibility: visible;
				height: 100vh;
				overflow-y: scroll;
				-webkit-overflow-scrolling: touch;
				-webkit-transition: all 0.45s ease-in-out;
				transition: all 0.45s ease-in-out;
			}

			ul {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				-webkit-overflow-scrolling: touch;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				padding: 60px 74px 260px;
			}

			li {
				width: 100%;
			}

			a {
				color: #fff;
				display: inline-block;
				width: 100%;
				text-align: center;
				padding: 18px 0;
				border-bottom: dashed 1px #fff;
			}
		}
	}

	@include pc() {
		@include flex($ai: center) {
			justify-content: space-between;
		}
		text-align: center;
		padding: 12px 20px 7px;
		border-bottom: 1px solid $green;

		&--inner {
			min-width: 1240px;
		}

		&--logo-btn-wrapper {
			@include flex($ai: center) {
				justify-content: space-between;
			}
		}

		&--head-logo {
			display: flex;
			align-items: center;

			img {
				width: 181px;
			}
		}

		&--sns-navi {
			@include flex($ai: center) {
				justify-content: space-between;
			}
		}

		&--sns {
			@include flex($ai: center) {
				justify-content: space-between;
			}

			a {
				display: flex;
				align-items: center;

				&:first-child {
					margin-right: 12px;
				}
			}

			img {
				width: 22px;
				height: 22px;
			}
		}

		&--navi {
			ul {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
			}

			li {
				margin-right: 32px;

				&:last-child {
					margin-right: 44px;
				}
			}

			a {
				color: $green;
				font-size: 10px;
				font-weight: bold;
			}
		}
	}
}
